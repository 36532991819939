import {
  LAYOUT_PG_CARD_MEDIUM,
  LAYOUT_PG_GRID,
  LAYOUT_PG_ONE_COLUMN,
  LAYOUT_SLIDER,
} from '@wix/communities-blog-client-common';
import { postListCssVars } from '../../../services/post-list-css-vars';

export const DEFAULT_TITLE_LINE_HEIGHT = 1.5;
export const DEFAULT_DESCRIPTION_LINE_HEIGHT = 1.5;
export const DEFAULT_CATEGORY_LABEL_LINE_HEIGHT = 1.5;

export const CONSTANTS = {
  [LAYOUT_PG_GRID]: {
    HEADER_HEIGHT: postListCssVars.HEADER_HEIGHT + postListCssVars.HEADER_MARGIN_BOTTOM,
    FOOTER_HEIGHT:
      postListCssVars.FOOTER_CONTENT_HEIGHT +
      postListCssVars.FOOTER_SEPARATOR_MARGIN +
      postListCssVars.FOOTER_PADDING_TOP +
      postListCssVars.BORDER_WIDTH,
    TITLE_MARGIN: postListCssVars.COMMON_TITLE_MARGIN,
    VERTICAL_PADDING: postListCssVars.COMMON_PADDING_TOP + postListCssVars.COMMON_PADDING_BOTTOM,
    CATEGORY_LABEL_MARGIN: postListCssVars.CATEGORY_LABEL_MARGIN_BOTTOM,
    DEFAULT_TITLE_FONT_SIZE: 22,
    DEFAULT_DESCRIPTION_FONT_SIZE: 14,
  },
  [LAYOUT_PG_CARD_MEDIUM]: {
    HEADER_HEIGHT: postListCssVars.HEADER_HEIGHT + postListCssVars.HEADER_MARGIN_BOTTOM,
    FOOTER_HEIGHT:
      postListCssVars.FOOTER_CONTENT_HEIGHT + postListCssVars.FOOTER_SEPARATOR_MARGIN + postListCssVars.BORDER_WIDTH,
    TITLE_MARGIN: 0,
    VERTICAL_PADDING: postListCssVars.COMMON_PADDING_TOP + postListCssVars.COMMON_PADDING_BOTTOM,
    CATEGORY_LABEL_MARGIN: postListCssVars.CATEGORY_LABEL_MARGIN_BOTTOM,
    DEFAULT_TITLE_FONT_SIZE: 22,
    DEFAULT_DESCRIPTION_FONT_SIZE: 14,
  },
  [LAYOUT_PG_ONE_COLUMN]: {
    HEADER_HEIGHT: postListCssVars.HEADER_HEIGHT + postListCssVars.HEADER_MARGIN_BOTTOM,
    FOOTER_HEIGHT:
      postListCssVars.FOOTER_CONTENT_HEIGHT +
      postListCssVars.FOOTER_SEPARATOR_MARGIN +
      postListCssVars.FOOTER_PADDING_TOP +
      postListCssVars.BORDER_WIDTH,
    TITLE_MARGIN: postListCssVars.COMMON_TITLE_MARGIN,
    VERTICAL_PADDING: postListCssVars.COMMON_PADDING_TOP + postListCssVars.COMMON_PADDING_BOTTOM,
    CATEGORY_LABEL_MARGIN: postListCssVars.CATEGORY_LABEL_MARGIN_BOTTOM,
    DEFAULT_TITLE_FONT_SIZE: 26,
    DEFAULT_DESCRIPTION_FONT_SIZE: 16,
  },
  [LAYOUT_SLIDER]: {
    HEADER_HEIGHT: postListCssVars.HEADER_HEIGHT + postListCssVars.HEADER_MARGIN_BOTTOM,
    FOOTER_HEIGHT:
      postListCssVars.FOOTER_CONTENT_HEIGHT +
      postListCssVars.FOOTER_SEPARATOR_MARGIN +
      postListCssVars.FOOTER_PADDING_TOP +
      postListCssVars.BORDER_WIDTH,
    TITLE_MARGIN: postListCssVars.DEFAULT_TITLE_MARGIN + postListCssVars.SLIDER_TITLE_MARGIN_TOP,
    VERTICAL_PADDING: postListCssVars.SLIDER_PADDING_TOP + postListCssVars.SLIDER_PADDING_BOTTOM,
    CATEGORY_LABEL_MARGIN: postListCssVars.SLIDER_CATEGORY_LABEL_MARGIN_BOTTOM,
    DEFAULT_TITLE_FONT_SIZE: 22,
    DEFAULT_DESCRIPTION_FONT_SIZE: 12,
  },
};
